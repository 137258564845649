import React from "react";
import Layout from "../../components/Layout/layout";
import BlogPage from "../../components/Blogs/index";
import SEO from "../../components/SEO/seo";

const Blog = () => {
	return (
		<Layout>
			<SEO
				title="Blog- SchnelleinTaxi"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Der SchnelleinTaxi-Service ist in Deutschland tätig und bietet seinen Kunden einen professionellen Taxiservice."
			/>
			<BlogPage />
		</Layout>
	);
};

export default Blog;
